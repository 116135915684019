import { StaticImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Container } from '../components/container'
import { Layout } from '../components/layout'
import { SlideCaption, Slider } from '../components/slider'
import circleCheck from '../assets/images/circle_check.svg'
import plusCircleOutline from '../assets/images/plus_circle_outline.svg'

const ServicesPage: FC = () => {
  const { t } = useTranslation('services')

  return (
    <Layout title={t('documentTitle')} activePage="/services/">
      <Slider interval={2000}>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-services1.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.first')}</small>
          </SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-services2.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.second')}</small>
          </SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-services3.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.third')}</small>
          </SlideCaption>
        </>
      </Slider>

      <BlueSection>
        <Container>
          <ul>
            <li>{t('checklist.first')}</li>
            <li>{t('checklist.second')}</li>
            <li>{t('checklist.third')}</li>
            <li>{t('checklist.fourth')}</li>
            <li>{t('checklist.fifth')}</li>
          </ul>
        </Container>
      </BlueSection>

      <DetailsContainer>
        <div className="title">
          <h2>{t('details.title')}</h2>
          <p>{t('details.subtitle')}</p>
        </div>
        <ul>
          <li>{t('details.1')}</li>
          <li>{t('details.2')}</li>
          <li>{t('details.3')}</li>
          <li>{t('details.4')}</li>
          <li>{t('details.5')}</li>
          <li>{t('details.6')}</li>
          <li>{t('details.7')}</li>
          <li>{t('details.8')}</li>
          <li>{t('details.9')}</li>
          <li>{t('details.10')}</li>
          <li>{t('details.11')}</li>
        </ul>
      </DetailsContainer>
    </Layout>
  )
}

export default ServicesPage

const BlueSection = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.brand};
    color: ${theme.colors.white};

    ul {
      padding-top: 1rem;
      padding-bottom: 1rem;

      ${theme.media.min('tablet_w')} {
        padding-top: 1rem;
        padding-bottom: 1rem;
        column-count: 2;
      }

      li {
        background: url(${circleCheck}) no-repeat left top;
        padding-left: 1.875rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  `}
`

const DetailsContainer = styled(Container)`
  ${({ theme }) => css`
    .title {
      background: url(${plusCircleOutline}) no-repeat left top;
      padding-left: 2.75rem;

      h2 {
        font-family: ${theme.typography.raleway};
        font-size: 1.5rem;
        font-weight: 500;
        color: ${theme.colors.brand};
        margin-bottom: 0;
        line-height: 1.33;
      }

      p {
        color: ${theme.colors.brand};
      }
    }

    ul {
      padding-top: 1rem;
      padding-bottom: 1rem;

      ${theme.media.min('tablet_w')} {
        padding-top: 2.625rem;
        padding-bottom: 3.875rem;
        column-count: 2;
      }

      li {
        &:not(:last-of-type) {
          border-bottom: 2px solid ${theme.colors.accent};
          padding-bottom: 1rem;
        }

        ${theme.media.min('tablet_w')} {
          width: 90%;

          &:nth-of-type(6) {
            border: none;
            padding: 0;
          }
        }
      }
    }
  `}
`
